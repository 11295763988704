import { createContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TutorialRoutes from '@src/routes/TutorialRoutes';

export const TutorialStoreContext = createContext({});

export function TutorialStoreProvider({ children }) {
  const [isShowTutorial, setIsShowTutorial] = useState(false);

  const providerValues = useMemo(
    () => ({
      setIsShowTutorial,
    }),
    []
  );

  return (
    <TutorialStoreContext.Provider value={providerValues}>
      {isShowTutorial ? <TutorialRoutes /> : children}
      <Outlet />
    </TutorialStoreContext.Provider>
  );
}
