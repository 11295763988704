export const createLocalStorageCache = (key) => {
  const value = (() => { try { return JSON.parse(localStorage.getItem(key))} catch(e) { return '' }})();

  function set(newValue) {
    if (!newValue) {
      return localStorage.removeItem(key);
    }
    return localStorage.setItem(key, JSON.stringify(newValue));
  }

  return { value, set };
};
