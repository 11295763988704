import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FaSync } from 'react-icons/fa';

import './ModalSignatureWaysPlay.scss';

import { arrStates } from '@src/utils/constants';

import { billingAPI } from '@src/services/ways-api/billing-api';
import SignatureWaysPlaySchema from '@src/validations/SignatureWaysPlaySchema';
import { cpfMask, cepMask, removeMask } from '@utils/inputMasks';

import InputGroup from '@src/components/InputGroup';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import CreditCard from '@src/components/CreditCard';
import LoaderSpinner from '@src/components/LoaderSpinner';

import { useAccount } from '@src/hooks/useAccount';
import { appendScript } from '@src/utils/appendScript';
import { userDataAPI } from '@services/ways-api/userdata-api';

export default function FormSignatureWaysPlay({ onClose }) {
  const { account } = useAccount();

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignatureWaysPlaySchema),
    defaultValues: {
      phonecc: account?.phonecc || '55',
      phonenum: account?.phonenum || '',
    },
  });

  const [number, setNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiration, setExpiration] = useState('');

  async function setInputAddressByZipCode(zipCode) {
    if (!zipCode) return;

    setIsLoading(true);
    const endpoint = `https://viacep.com.br/ws/${zipCode}/json/`;
    try {
      const { bairro, localidade, logradouro, uf } = await fetch(endpoint).then(
        (resp) => resp.json()
      );
      setValue('street', logradouro, { shouldValidate: true });
      setValue('district', bairro, { shouldValidate: true });
      setValue('city', localidade, { shouldValidate: true });
      setValue('state', uf, { shouldValidate: true });
    } catch (error) {
      toast.error('Erro ao buscar os dados pelo CEP', {
        theme: 'colored',
        autoClose: 6000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  appendScript('https://js.iugu.com/v2');
  const checkCreditCardIsValid = () => {
    const name = cardHolder.match(/^(.+)\s(.+)$/);
    const exp = expiration.match(/^(\d{2})\/?(\d{2})$/);
    const cc = window.Iugu.CreditCard(
      number.replace(/[^\d]*/g, ''),
      exp[1],
      `20${exp[2]}`,
      name[1],
      name[2],
      cvv
    );

    return cc.valid() ? cc.toData() : false;
  };

  async function confirmSignatureWaysPlay(data) {
    setIsLoading(true);
    window.Iugu.setAccountID('7DABBBF687DD574C86D485B36F743A65');
    console.log('iugu env: ', process.env?.REACT_APP_IUGU_ENV !== 'prod');
    window.Iugu.setTestMode(process.env?.REACT_APP_IUGU_ENV !== 'prod');

    const ccData = checkCreditCardIsValid();

    if (!ccData) {
      toast.error('Dados do cartão estão inválidos', {
        theme: 'colored',
        autoClose: 6000,
      });
      setIsLoading(false);
      return;
    }

    Iugu.createPaymentToken(ccData, async (response) => {
      if (response.errors) {
        toast.error('Ocorreu um erro durante a verificação do seu cartão.', {
          theme: 'colored',
          autoClose: 6000,
        });
        setIsLoading(false);
        return;
      }

      try {
        await billingAPI.enableSubscribe(
          Object.assign(data, {
            id: account.id,
            cpf: removeMask(data.cpf),
            zip_code: removeMask(data.zip_code),
            cc_token: response.id,
          })
        );
      } catch (error) {
        toast.error(
          'Não foi possível confirmar sua assinatura, verifique seus dados e tente novamente.',
          {
            theme: 'colored',
            autoClose: 6000,
          }
        );
      } finally {
        setIsLoading(false);
      }
    });
  }

  const logInputChanged = async (label, value) => {
    await userDataAPI.registerAction(label, value);
  };

  const planValue = parseFloat(account?.subscription_price_brl).toLocaleString(
    'pt-br',
    { style: 'currency', currency: 'BRL' }
  );

  return (
    <>
      <form
        className="Form-signature-ways-play"
        onSubmit={handleSubmit(confirmSignatureWaysPlay)}
      >
        <h2 className="Form-signature-ways-play__title">Ways Play</h2>

        <p className="Form-signature-ways-play__description">
          Assine o plano mensal por <strong>{planValue}</strong> para ter acesso
          a todos os benefícios
        </p>

        <div className="Form-signature-ways-play__fields__line1">
          <InputGroup
            label="CPF"
            id="cpf"
            placeholder="Insira seu CPF"
            isError={errors.cpf}
            helperText={errors.cpf?.message}
            onKeyUp={cpfMask}
            className="Form-signature-ways-play__fields__cpf"
            {...register('cpf', {
              required: true,
              onBlur: (e) => {logInputChanged('Acessou tela de Pagamento', 'preencheu CPF: ' + e?.target?.value);}
            })}
          />

          <InputGroup
            label="Celular"
            className="Form-signature-ways-play__fields__phone__wrapper"
            isError={errors.phonenum}
            helperText={errors.phonenum?.message}
          >
            <div className="Form-signature-ways-play__fields__phone__wrapper__fields">
              <Input
                wrapperClassName="Form-signature-ways-play__fields__phoneccprefix"
                readOnly
                value="+"
              />

              <Input
                wrapperClassName="Form-signature-ways-play__fields__phonecc"
                isError={errors.phonecc}
                inputHelperText={errors.phonecc?.message}
                {...register('phonecc', {
                  required: true,
                })}
              />

              <Input
                placeholder="Insira seu celular"
                wrapperClassName="Form-signature-ways-play__fields__phone"
                {...register('phonenum', {
                  required: true,
                  onBlur: (e) => {logInputChanged('Acessou tela de Pagamento', 'preencheu Celular: ' + e?.target?.value);}
                })}
              />
            </div>
          </InputGroup>
        </div>

        <div className="Form-signature-ways-play__fields">
          <div className="Form-signature-ways-play__fields__cep">
            <InputGroup
              label="CEP"
              id="cep"
              placeholder="Insira seu CEP"
              isError={errors.zip_code}
              helperText={errors.zip_code?.message}
              onKeyUp={cepMask}
              {...register('zip_code', {
                  required: true,
                  onBlur: (e) => {logInputChanged('Acessou tela de Pagamento', 'preencheu CEP: ' + e?.target?.value);}
                }
              )}
              /*
                  onBlur={(e) =>
                    setInputAddressByZipCode(removeMask(e.target.value))
                  } */
            />
            <button
              type="button"
              title="Preencher endereço automaticamente a partir do CEP"
              onClick={() =>
                setInputAddressByZipCode(removeMask(watch('zip_code')))
              }
            >
              <FaSync />
            </button>
          </div>

          <div className="Form-signature-ways-play__address-fields">
            <InputGroup
              label="Logradouro"
              id="street"
              isError={errors.street}
              helperText={errors.street?.message}
              className="Form-signature-ways-play__fields__street"
              {...register('street', { required: true })}
            />

            <InputGroup
              label="Número"
              id="number"
              type="number"
              isError={errors.number}
              helperText={errors.number?.message}
              className="Form-signature-ways-play__fields__number"
              {...register('number', { required: true })}
            />

            <InputGroup
              label="Complemento"
              id="complement"
              isError={errors.complement}
              helperText={errors.complement?.message}
              className="Form-signature-ways-play__fields__complement"
              {...register('complement', { required: true })}
            />

            <InputGroup
              label="Bairro"
              id="district"
              isError={errors.district}
              helperText={errors.district?.message}
              className="Form-signature-ways-play__fields__district"
              {...register('district', { required: true })}
            />

            <InputGroup
              label="Cidade"
              id="city"
              isError={errors.city}
              helperText={errors.city?.message}
              className="Form-signature-ways-play__fields__city"
              {...register('city', { required: true })}
            />

            <InputGroup
              label="Estado"
              id="state"
              className="Form-signature-ways-play__fields__state__dropdown__wrapper"
              isError={errors.state}
              helperText={errors.state?.message}
            >
              <select
                id="dropdown_state"
                className="Form-signature-ways-play__fields__state__dropdown"
                {...register('state', { required: true })}
              >
                <option value="">&hellip;</option>
                {arrStates?.map((s) => (
                  <option key={s.uf} value={s.uf}>
                    {s.uf}
                  </option>
                ))}
              </select>
            </InputGroup>
          </div>
        </div>

        <CreditCard
          setNumber={setNumber}
          setCvv={setCvv}
          setCardHolder={setCardHolder}
          setExpiration={setExpiration}
        />

        <div className="Form-signature-ways-play__buttons">
          {!!onClose && (
            <Button type="button" styleButton="secondary" onClick={onClose}>
              Cancelar
            </Button>
          )}
          <Button type="submit">Confirmar Assinatura</Button>
        </div>
      </form>

      {isLoading && <LoaderSpinner />}
    </>
  );
}
