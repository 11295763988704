import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaQuestion,
} from 'react-icons/fa';

import './Footer.scss';

import { TutorialStoreContext } from '@src/store/tutorialStore';

function Footer() {
  const { setIsShowTutorial } = useContext(TutorialStoreContext);

  const urlFacebook = 'https://www.facebook.com/ways.edu/';
  const urlInstagram = 'https://www.instagram.com/ways.education/';
  const urlLinkedin = 'https://www.linkedin.com/company/wayseducation';
  const urlWhatsApp =
    'https://api.whatsapp.com/send?phone=5531981068216&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20Ways.';

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__social-ways">
          <a
            className="footer__icon"
            href={urlFacebook}
            target="__blank"
            rel="noopener"
          >
            <FaFacebookF />
          </a>
          <a
            className="footer__icon"
            href={urlInstagram}
            target="__blank"
            rel="noopener"
          >
            <FaInstagram />
          </a>
          <a
            className="footer__icon"
            href={urlLinkedin}
            target="__blank"
            rel="noopener"
          >
            <FaLinkedinIn />
          </a>
          <a
            className="footer__icon"
            href={urlWhatsApp}
            target="__blank"
            rel="noopener"
          >
            <FaWhatsapp />
          </a>
        </div>
        <p className="footer__copyright">
          2022 &copy; Todos os direitos reservados
        </p>
        <nav>
          <ul className="footer__pages">
            <li>
              <Link to="/terms">Termos de uso</Link>
            </li>
            <li>
              <Link to="/policy">Política de privacidade</Link>
            </li>
            <li>
              <button
                className="footer__tutorial-button"
                type="button"
                onClick={() => setIsShowTutorial(true)}
                title="Fazer o tutorial"
              >
                <FaQuestion />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default memo(Footer);
