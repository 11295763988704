import axios from 'axios';

export const axiosHttpRequest = async (data) => {
  const httpResponse = await axios({
    url: data.url,
    method: data.method,
    data: data.body,
    headers: data.headers,
  });
  return {
    data: httpResponse.data,
    statusCode: httpResponse.status,
  };
};
