import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import './ModalSignatureWaysPlayActive.scss';

import { billingAPI } from '@src/services/ways-api/billing-api';

import Modal from '@src/components/Modal';
import Button from '@src/components/Button';
import LoaderSpinner from '@src/components/LoaderSpinner';

import { useAccount } from '@src/hooks/useAccount';

export default function ModalSignatureWaysPlayActive({ onClose }) {
  const { account } = useAccount();

  const [isLoading, setIsLoading] = useState(false);

  const [invoiceData, setInvoiceData] = useState();

  return (
    <>
      <Modal maxWidth="700px" onClose={onClose}>
        <div className="Modal-signature-ways-play-pending">

          <h2 className="Modal-signature-ways-play-pending__title">Assinatura Ways Play</h2>

          <p className="Modal-signature-ways-play-pending__description">
            { account?.expires_at_dt && ( <> Renova dia: {account?.expires_at_dt} </> ) }
          </p>

          <div className="Modal-signature-ways-play-pending__content">
          </div>

          <div className="Modal-signature-ways-play-pending__buttons">
            <a href={invoiceData?.url} onClick={onClose}>Ok</a>
              
          </div>
        </div>
      </Modal>

      {isLoading && <LoaderSpinner />}
    </>
  );
}
