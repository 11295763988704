import { useState, memo, useCallback } from 'react';

import './UserMenu.scss';

import { useAccount } from '@src/hooks/useAccount';

import DefaultProfileImage from '../DefaultProfileImage';
import ModalSignatureWaysPlay from '../ModalSignatureWaysPlay';
import ModalSignatureWaysPlayPending from '../ModalSignatureWaysPlayPending';
import ModalSignatureWaysPlayActive from '../ModalSignatureWaysPlayActive';

function UserMenu() {
  const { account } = useAccount();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [modalIsActive, setModalIsActive] = useState(false);

  const [modalPendingIsActive, setModalPendingIsActive] = useState(false);
  const [modalActiveIsActive, setModalActiveIsActive] = useState(false);

  const logoutUser = useCallback(async () => {

    const impersonated = account?.impersonated??false;

    const key = '@Ways:token';
    await localStorage.removeItem(key);

    if(impersonated){
      const actualStaffUserToken = localStorage.getItem('actualStaffUserToken');
      await localStorage.removeItem('actualStaffUserToken');
      localStorage.setItem(key, JSON.stringify(actualStaffUserToken));
    }

    await global.location.reload();
  }, []);

  const closeModal = useCallback(() => setModalIsActive(false), []);

  const closeModalPending = useCallback(() => setModalPendingIsActive(false), []);

  const closeModalActive = useCallback(() => setModalActiveIsActive(false), []);

  const handleClickOpenModal = useCallback(() => {
    console.log(account);
    if(!account?.subscription_status){
      setModalIsActive(true)
    }else{
      if(account?.is_active){
        setModalActiveIsActive(true)
      }else{
        setModalPendingIsActive(true)
      }
    }
  });

  return (
    <>
      <div className="UserMenu">
        {!!account?.is_trial && (
          <button
            type="button"
            className="UserMenu__subscription"
            onClick={handleClickOpenModal}
          >
            <span className="UserMenu__subscription__tag__trial">Período grátis</span>
            { !isNaN(account?.expiration_days_left) && (
              <span className="UserMenu__subscription__expiration-days">
              { account.expiration_days_left == 0 && (
                <>expirado</>
              )}
              { account.expiration_days_left > 0 && (
                <>
                Expira em: {account.expiration_days_left} {account.expiration_days_left == 1 ? 'dia' : 'dias'} 
                </>
              )}
              </span>
            ) }
          </button>
        )}

        {!account?.is_suspended && !account?.is_active && account?.subscription_status == 'pending' && (
          <button
            type="button"
            className="UserMenu__subscription"
            onClick={handleClickOpenModal}
          >
            <span className="UserMenu__subscription__tag__pending">Assinatura pendente</span>
            <small>clique para atualizar</small>
          </button>
        )}

        {!!account?.is_suspended && (
          <button
            type="button"
            className="UserMenu__subscription"
            onClick={handleClickOpenModal}
          >
            <span className="UserMenu__subscription__tag__suspended">Assinatura suspensa</span>
            <small>clique para atualizar</small>
          </button>
        )}

        {false && !!account?.is_active && (
          <button
            type="button"
            className="UserMenu__subscription"
            onClick={handleClickOpenModal}
          >
            <span className="UserMenu__subscription__tag__active">Assinante</span>
          </button>
        )}

        <button
          type="button"
          className="UserMenu__button"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <DefaultProfileImage
            src={account?.avatar}
            alt={account.names.fullname}
          />
        </button>
        <nav
          className={
            isOpenMenu ? 'UserMenu__dropMenu --active' : 'UserMenu__dropMenu'
          }
        >
          <ul>
            <li>
              <button
                type="button"
                onClick={logoutUser}
                className="UserMenu__page"
              >
                Sair
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {modalIsActive && <ModalSignatureWaysPlay onClose={closeModal} />}

      {modalPendingIsActive && <ModalSignatureWaysPlayPending onClose={closeModalPending} />}

      {modalActiveIsActive && <ModalSignatureWaysPlayActive onClose={closeModalActive} />}
    </>
  );
}

export default memo(UserMenu);
