import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FaSync } from 'react-icons/fa';
//&#128260;

import './ModalSignatureWaysPlay.scss';

import FormSignatureWaysPlay from './form';

import { arrStates } from "@src/utils/constants";

import { billingAPI } from '@src/services/ways-api/billing-api';
import SignatureWaysPlaySchema from '@src/validations/SignatureWaysPlaySchema';
import { cpfMask, cepMask, removeMask, telephoneMask } from '@utils/inputMasks';

import Modal from '@src/components/Modal';
import InputGroup from '@src/components/InputGroup';
import Input from '@src/components/Input';
import Button from '@src/components/Button';
import LoaderSpinner from '@src/components/LoaderSpinner';

import { useAccount } from '@src/hooks/useAccount';

export default function ModalSignatureWaysPlay({ onClose }) {
  const { account } = useAccount();

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignatureWaysPlaySchema),
    defaultValues: {
      phonecc: account?.phonecc || '55',
      phonenum: account?.phonenum || '',
    }
  });

  async function setInputAddressByZipCode(zipCode) {
    if (!zipCode) return;

    setIsLoading(true);
    const endpoint = `https://viacep.com.br/ws/${zipCode}/json/`;
    try {
      const { bairro, localidade, logradouro, uf } = await fetch(endpoint).then(
        (resp) => resp.json()
      );
      setValue('street', logradouro, {shouldValidate: true});
      setValue('district', bairro, {shouldValidate: true});
      setValue('city', localidade, {shouldValidate: true});
      setValue('state', uf, {shouldValidate: true});
    } catch (error) {
      toast.error('Erro ao buscar os dados pelo CEP', {
        theme: 'colored',
        autoClose: 6000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function confirmSignatureWaysPlay(data) {
    setIsLoading(true);
    const formatData = {
      ...data,
      id: account.id,
      cpf: removeMask(data.cpf),
      zip_code: removeMask(data.zip_code),
    };
    try {
      await billingAPI.enableSubscribe(formatData);
      onClose();
    } catch (error) {
      toast.error('Não foi possível confirmar sua assinatura, verifique seus dados e tente novamente.', {
        theme: 'colored',
        autoClose: 6000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const planValue = parseFloat(account?.subscription_price_brl).toLocaleString(
    'pt-br',
    { style: 'currency', currency: 'BRL' }
  );

  return (
    <>
      <Modal maxWidth="700px" onClose={onClose}>
        <FormSignatureWaysPlay onClose={onClose} />
      </Modal>

      {isLoading && <LoaderSpinner />}
    </>
  );
}
