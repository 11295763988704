import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoaderSpinner from '@src/components/LoaderSpinner';
import { Suspense, lazy } from 'react';
import BasePage from '@src/components/BasePage';
import { TutorialStoreProvider } from '@store/tutorialStore';
import { useAccount } from '@src/hooks/useAccount';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { RequireAuthRoutes } from './RequireAuthRoutes';

const LoginPage = lazy(() => import('@pages/authentication-pages/LoginPage'));
const RegisterPage = lazy(() =>
  import('@pages/authentication-pages/RegisterPage')
);
const ForgotPage = lazy(() => import('@pages/authentication-pages/ForgotPage'));

const HomePage = lazy(() => import('@pages/common-pages/HomePage'));
const ProfilePage = lazy(() => import('@pages/common-pages/ProfilePage'));
const CatalogPage = lazy(() => import('@pages/common-pages/CatalogPage'));
const ClassroomPage = lazy(() => import('@pages/common-pages/ClassroomPage'));
const CalendarPage = lazy(() => import('@pages/common-pages/CalendarPage'));
const BillingDataPage = lazy(() =>
  import('@pages/common-pages/BillingDataPage')
);
const CertificatesPage = lazy(() =>
  import('@pages/common-pages/CertificatesPage')
);

const ManageCatalogSectionStaffPage = lazy(() =>
  import('@pages/staff-pages/ManageCatalogSectionStaffPage')
);
const SubscribersStaffPage = lazy(() =>
  import('@pages/staff-pages/SubscribersStaffPage')
);
const CreateClassroomStaffPage = lazy(() =>
  import('@pages/staff-pages/CreateClassroomStaffPage')
);
const UpdateClassroomStaffPage = lazy(() =>
  import('@pages/staff-pages/UpdateClassroomStaffPage')
);
const BillingClientListStaffPage = lazy(() =>
  import('@pages/staff-pages/BillingClientListStaffPage')
);
const CompaniesListStaffPage = lazy(() =>
  import('@pages/staff-pages/CompaniesListStaffPage')
);
const ListAppointmentsStaffPage = lazy(() =>
  import('@pages/staff-pages/ListAppointmentsStaffPage')
);
const ClientStaffPage = lazy(() =>
  import('@pages/staff-pages/ClientStaffPage')
);

const PrivacyPolicyPage = lazy(() =>
  import('@pages/common-pages/PrivacyPolicyPage')
);
const TermsOfUsePage = lazy(() => import('@pages/common-pages/TermsOfUsePage'));

export default function AppRoutes() {
  const { account } = useAccount();

  return (
    <BrowserRouter>
      <Suspense fallback={<LoaderSpinner />}>
        <TutorialStoreProvider>
          <Routes>
            {/* Authentication routes */}
            <Route element={<AuthenticatedRoutes />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register">
                <Route index element={<RegisterPage />} />
                <Route path=":company" element={<RegisterPage />} />
              </Route>
              <Route path="/forgot" element={<ForgotPage />} />
            </Route>

            {/* Common routes */}
            <Route
              element={
                <RequireAuthRoutes allowedRoles={['responsible', 'staff']} />
              }
            >
              <Route
                path="/"
                element={
                  <BasePage>
                    <HomePage />
                  </BasePage>
                }
              />
              <Route
                path="/meus-dados"
                element={
                  <BasePage>
                    <ProfilePage />
                  </BasePage>
                }
              />
              <Route
                path="/catalogo"
                element={
                  <BasePage>
                    <CatalogPage />
                  </BasePage>
                }
              />
              <Route
                path="/sala-de-aula/:classId"
                element={
                  <BasePage>
                    <ClassroomPage account={account} />
                  </BasePage>
                }
              />
              <Route
                path="/calendario"
                element={
                  <BasePage>
                    <CalendarPage />
                  </BasePage>
                }
              />
              <Route
                path="/pagamento/dados"
                element={
                  <BasePage>
                    <BillingDataPage />
                  </BasePage>
                }
              />
              <Route
                path="/certificados"
                element={
                  <BasePage>
                    <CertificatesPage />
                  </BasePage>
                }
              />
            </Route>

            {/* Staff Routes */}
            <Route element={<RequireAuthRoutes allowedRoles={['staff']} />}>
              <Route path="/catalogo">
                <Route
                  path="gerenciar-secoes"
                  element={
                    <BasePage>
                      <ManageCatalogSectionStaffPage />
                    </BasePage>
                  }
                />
                <Route
                  path="criar-aula"
                  element={
                    <BasePage>
                      <CreateClassroomStaffPage />
                    </BasePage>
                  }
                />
              </Route>
              <Route path="/sala-de-aula/:classId">
                <Route
                  path="inscritos"
                  element={
                    <BasePage>
                      <SubscribersStaffPage />
                    </BasePage>
                  }
                />
                <Route
                  path="editar"
                  element={
                    <BasePage>
                      <UpdateClassroomStaffPage />
                    </BasePage>
                  }
                />
              </Route>
              <Route path="/staff">
                <Route
                  path="aulas"
                  element={
                    <BasePage>
                      <ListAppointmentsStaffPage />
                    </BasePage>
                  }
                />
                <Route
                  path="empresas"
                  element={
                    <BasePage>
                      <CompaniesListStaffPage />
                    </BasePage>
                  }
                />
              </Route>
              <Route
                path="/clientes"
                element={
                  <BasePage>
                    <ClientStaffPage />
                  </BasePage>
                }
              />
              <Route
                path="/financeiro/clientes"
                element={
                  <BasePage>
                    <BillingClientListStaffPage />
                  </BasePage>
                }
              />
            </Route>

            {/* Public routes */}
            <Route path="/policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms" element={<TermsOfUsePage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </TutorialStoreProvider>
      </Suspense>
    </BrowserRouter>
  );
}
