import { authAxiosHttpRequest } from '@src/infra/http/axios/auth-axios-http-request-decorator';
import { createWaysApiUrl } from '@src/infra/http/ways-api-url';

export const userDataAPI = {
  async registerAction(label, sublabel, data) {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({
        service: `userdata/register_action`,
      }),
      method: 'POST',
      body: {
        label,
        sublabel: sublabel?sublabel:'',
        data: data ? JSON.stringify(data) : '',
      },
    });
    return response;
  },

};
