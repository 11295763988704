import { useState } from 'react';
import {
  dateMask,
  numberCreditCardMask,
  onlyNumberMask,
} from '../../utils/inputMasks';

import './styles.scss';

export default function (props) {
  return (
    <div className="usable-creditcard-form">
      <div className="wrapper">
        <div className="input-group nmb_a">
          <div className="icon ccic-brand" />
          <input
            type="text"
            autoComplete="off"
            className="credit_card_number"
            data-iugu="number"
            placeholder="Número do Cartão"
            onKeyUp={numberCreditCardMask}
            value={props?.number}
            onChange={(e) => props?.setNumber(e.target.value)}
          />
        </div>
        <div className="input-group nmb_b">
          <div className="icon ccic-cvv" />
          <input
            type="text"
            autoComplete="off"
            className="credit_card_cvv"
            data-iugu="verification_value"
            onKeyUp={onlyNumberMask}
            maxLength="4"
            placeholder="Cód."
            onChange={(e) => props?.setCvv(e.target.value)}
          />
        </div>
        <div className="input-group nmb_c">
          <div className="icon ccic-name" />
          <input
            type="text"
            className="credit_card_name"
            data-iugu="full_name"
            placeholder="Titular do Cartão"
            onChange={(e) => props?.setCardHolder(e.target.value)}
          />
        </div>
        <div className="input-group nmb_d">
          <div className="icon ccic-exp" />
          <input
            type="text"
            autoComplete="off"
            className="credit_card_expiration"
            data-iugu="expiration"
            onKeyUp={dateMask}
            maxLength="5"
            placeholder="MM/AA"
            onChange={(e) => props?.setExpiration(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
