export const removeMask = (value) => value.replace(/[^A-Za-z0-9]/g, '');

export const cpfMask = (e) => {
  e.currentTarget.maxLength = 14;

  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2');
  value = value.replace(/(-\d{2})\d+?$/, '$1');

  e.currentTarget.value = value;
};

export function cepMask(e) {
  e.currentTarget.maxLength = 9;

  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{5})(\d)/, '$1-$2');
  value = value.replace(/(-\d{3})\d+?$/, '$1');

  e.currentTarget.value = value;
}

export function telephoneMask(e) {
  e.currentTarget.maxLength = 16;

  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  value = value.replace(
    /(\(\d{2}\)) (\d{1})(\d{3})-(\d{1})(\d{4})/,
    '$1 $2 $3$4-$5'
  );
  value = value.replace(/(-\d{4})\d+?$/, '$1');

  e.currentTarget.value = value;
}

export function dateMask(e) {
  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\/\d{2})(\d)/, '$1/$2');

  e.currentTarget.value = value;
}

export function numberCreditCardMask(e) {
  e.currentTarget.maxLength = 19;

  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{4})(\d)/, '$1 $2');
  value = value.replace(/(\d{4})(\d)/, '$1 $2');
  value = value.replace(/(\d{4})(\d)/, '$1 $2');
  value = value.replace(/(\d{4})\d+?$/, '$1');

  e.currentTarget.value = value;
}

export function validCreditCardMask(e) {
  e.currentTarget.maxLength = 5;

  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{2})\d+?$/, '$1');

  e.currentTarget.value = value;
}

export function onlyNumberMask(e) {
  let { value } = e.currentTarget;

  value = value.replace(/\D/g, '');

  e.currentTarget.value = value;
}
