import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'intro.js/introjs.css';

import BasePage from '@src/components/BasePage';
import LoaderSpinner from '@src/components/LoaderSpinner';
import TutorialOutputOverlay from '../pages/tutorial-pages/components/TutorialOutputOverlay';

const CalendarTutorialPage = lazy(() =>
  import('@pages/tutorial-pages/CalendarTutorialPage')
);
const CatalogTutorialPage = lazy(() =>
  import('@pages/tutorial-pages/CatalogTutorialPage')
);
const ClassroomTutorialPage = lazy(() =>
  import('@pages/tutorial-pages/ClassroomTutorialPage')
);
const HomeTutorialPage = lazy(() =>
  import('@pages/tutorial-pages/HomeTutorialPage')
);

export default function ResponsibleRoutes() {
  const routes = [
    {
      path: '/',
      page: <HomeTutorialPage />,
    },
    {
      path: '/sala-de-aula/',
      page: <ClassroomTutorialPage />,
    },
    {
      path: '/catalogo',
      page: <CatalogTutorialPage />,
    },
    {
      path: '/calendario',
      page: <CalendarTutorialPage />,
    },
  ];

  return (
    <>
    <Routes>
      {routes.map(({ path, page }) => (
        <Route
          key={path}
          path={path}
          element={
            <Suspense fallback={<LoaderSpinner />}>
              <BasePage>{page}</BasePage>
            </Suspense>
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
      <TutorialOutputOverlay />
      </>
  );
}
