import { authAxiosHttpRequest } from '@src/infra/http/axios/auth-axios-http-request-decorator';
import { createWaysApiUrl } from '@src/infra/http/ways-api-url';

export const billingAPI = {
  async enableSubscribe(data) {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'billing/enable_subscription' }),
      method: 'POST',
      body: data,
    });
    return response;
  },

  async getSubscriptionInvoiceData() {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'billing/sub/invoice' }),
      method: 'GET',
    });
    return response;
  },
};
