import { authAxiosHttpRequest } from '@src/infra/http/axios/auth-axios-http-request-decorator';
import { axiosHttpRequest } from '@src/infra/http/axios/axios-http-request';
import { createWaysApiUrl } from '@src/infra/http/ways-api-url';
import { uploadFileToS3 } from '@src/services/amazonaws';

export const accountAPI = {
  async gettAccountClassrooms() {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'mydata/aulas' }),
      method: 'GET',
    });
    return response;
  },

  async getAccountCredits() {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'mydata/credits' }),
      method: 'GET',
    });
    return response;
  },

  async getAccountData() {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'mydata' }),
      method: 'GET',
    });
    return response;
  },

  async addAccountCredits(credits) {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({
        service: 'mydata/credits/add',
        params: { n: credits },
      }),
      method: 'POST',
    });
    return response;
  },

  async updatePassword(data) {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: `change_password` }),
      method: 'PUT',
      body: {
        current_password: data.current_password,
        new_password: data.new_password,
        new_password_confirm: data.new_password_confirm,
      },
    });
    return response;
  },

  async redefinePassword(email) {
    const response = await axiosHttpRequest({
      url: createWaysApiUrl({ service: 'redefine_password' }),
      method: 'PUT',
      body: { email },
    });
    return response;
  },

  async updateAccountAvatar(objectFile) {
    const { data } = await authAxiosHttpRequest({
      url: createWaysApiUrl({
        service: 'mydata/avatar/upload',
        params: { ext: 'png' },
      }),
      method: 'GET',
    });

    await uploadFileToS3(data.data, objectFile);

    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'mydata/avatar' }),
      method: 'POST',
      body: {
        key: data.data.url_fields.key,
      },
    });
    return response;
  },

  async updateAccountData(data) {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'mydata' }),
      method: 'POST',
      body: data,
    });
    return response;
  },

  async getCertificates() {
    const response = await authAxiosHttpRequest({
      url: createWaysApiUrl({ service: 'mydata/certificados' }),
      method: 'GET',
    });
    return response;
  },
};
