import { memo } from 'react';
import './LoaderSpinner.scss';

function LoaderSpinner() {
  return (
    <div className="LoaderSpinner">
      <div className="LoaderSpinner__spinner" />
    </div>
  );
}

export default memo(LoaderSpinner);
