import { forwardRef, memo, useEffect, useState } from 'react';

import './InputGroup.scss';

import Input from '@src/components/Input';

const InputGroup = forwardRef(
  ({ label, helperText, children, id, icon, className, ...rest }, ref) => {
    const [classNames, setClassNames] = useState(['Input-group']);

    useEffect(() => {
      if (className) {
        setClassNames((oldState) => [...oldState, className]);
      }
    }, [className]);

    return (
      <div className={classNames.join(' ')}>
        {!!label && (
          <label html={id} className="Input-group__label">
            {label}
          </label>
        )}

        {children || <Input id={id} ref={ref} icon={icon} {...rest} />}

        {helperText && (
          <span className="Input-group__helper-text">{helperText}</span>
        )}
      </div>
    );
  }
);

export default memo(InputGroup);
