import axios from 'axios';

export async function uploadFileToS3(presignedPostData, file) {
  try {
    const formData = new FormData();

    Object.keys(presignedPostData.url_fields).forEach((key) => {
      formData.append(key, presignedPostData.url_fields[key]);
    });

    formData.append('file', file);

    await axios.post(presignedPostData.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return true;
  } catch (error) {
    return false;
  }
}
