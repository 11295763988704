import { useEffect } from 'react';
import Confetti from 'react-confetti';
import { FaTimes } from 'react-icons/fa';

import './Modal.scss';

export default function Modal({ children, onClose, maxWidth, effect }) {
  const effectsMap = {
    confetti: <Confetti recycle={false} numberOfPieces={500} />,
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return function cleanup() {
      document.body.style.overflowY = 'visible';
    };
  });

  return (
    <div className="Modal">
      {effectsMap[effect]}
      <div className="Modal__container" style={{ maxWidth }}>
        {onClose ? (
          <button
            type="button"
            className="Modal__close-button"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        ) : null}
        {children}
      </div>
    </div>
  );
}
