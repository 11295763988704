import { useState, memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Header.scss';

import { useAccount } from '@src/hooks/useAccount';

import logoWays from '@assets/images/ways-logo.png';
import UserMenu from '../UserMenu';

import isHmg from '@src/utils/isHmg';

function Header() {
  const location = useLocation();
  const { account } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((oldState) => !oldState);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  }, [isMenuOpen]);

  return (
    <header className="Header">
      <div className="Header__container">
        <div className="Header__logos">
          <Link to="/" className="Header__logo-ways">
            <img
              src={logoWays}
              alt="Logo da Ways Education"
              width="91.53"
              height="28"
              loading="lazy"
              className="Header__logo"
            />
          </Link>
          {account?.company?.logo && (
            <>
              <img
                src="https://d335luupugsy2.cloudfront.net/cms/files/167544/1651664374/$b3z2jbnirx9"
                alt="Imagem de um + com um fundo redondo colorido"
                width="28"
                height="28"
                className="Header__plus"
                loading="lazy"
              />
              <img
                src={account.company.logo}
                alt={`Logo empresa - ${account.company.name}`}
                loading="lazy"
                className="Header__logo"
              />
            </>
          )}
        </div>

        <nav
          className={
            isMenuOpen ? 'Header__nav Header__nav--open' : 'Header__nav'
          }
        >
          <ul>
            <li>
              <Link
                to="/"
                className={
                  location.pathname === '/'
                    ? 'Header__page Header__page--active'
                    : 'Header__page'
                }
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/meus-dados"
                className={
                  location.pathname === '/meus-dados'
                    ? 'Header__page Header__page--active'
                    : 'Header__page'
                }
              >
                Meus dados
              </Link>
            </li>
            {account?.role !== 'partner' && (
              <li>
                <Link
                  to="/catalogo"
                  className={
                    location.pathname.match(/^\/catalogo/)
                      ? 'Header__page Header__page--active'
                      : 'Header__page'
                  }
                >
                  Catálogo de atividades
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/calendario"
                className={
                  location.pathname === '/calendario'
                    ? 'Header__page Header__page--active'
                    : 'Header__page'
                }
              >
                Agenda Ways
              </Link>
            </li>
          </ul>
        </nav>

        <UserMenu />

        <button
          type="button"
          className={
            isMenuOpen
              ? 'Header__menu-icon Header__menu-icon--open'
              : 'Header__menu-icon'
          }
          onClick={toggleMenu}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
      {(isHmg() || account?.impersonated) && (
      <div className="Header__staff-band">
        {isHmg() && (<span>Ambiente de homologação</span>)}
        {!isHmg() && (<span>&nbsp;</span>)}
        { account?.impersonated && (
        <span>{account?.impersonated_by_user?.name} como <b>{account.name}</b></span>
        )}
      </div>
      )}
    </header>
  );
}

export default memo(Header);
