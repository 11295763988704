import { forwardRef, useEffect, memo, useCallback, useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

import './Input.scss';

const Input = forwardRef(
  ({ isError, icon, type = 'text', required, wrapperClassName, inputHelperText, ...inputProperties }, ref) => {
    const [inputType, setInputType] = useState(type);
    const [className, setClassName] = useState('Input');

    const togglePasswordDisplay = useCallback(
      () =>
        setInputType((oldState) => {
          if (oldState === 'text') {
            return 'password';
          }
          return 'text';
        }),
      []
    );

    useEffect(() => {
      const classNames = !!wrapperClassName ? [...['Input'], ...[wrapperClassName]] : ['Input'];
      if (type === 'password') classNames.push('Input--password');
      if (icon) classNames.push('Input--has-icon');
      if (isError) classNames.push('Input--error');
      setClassName(classNames.join(' '));
    }, [icon, isError, type]);

    return (
      <div className={className} data-testid="input-component">
        <input type={inputType} ref={ref} {...inputProperties} />

        {icon && (
          <span data-testid="input-component__icon" className="Input__icon">
            {icon}
          </span>
        )}

        {type === 'password' && (
          <button
            type="button"
            className="Input__icon Input__icon--toggle-password-button"
            onClick={togglePasswordDisplay}
          >
            {inputType === 'text' ? <FaRegEye /> : <FaRegEyeSlash />}
          </button>
        )}


        {inputHelperText && (
          <span className="Input-group__helper-text">{inputHelperText}</span>
        )}

      </div>
    );
  }
);

export default memo(Input);
