import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import './ModalSignatureWaysPlayPending.scss';

import { billingAPI } from '@src/services/ways-api/billing-api';

import Modal from '@src/components/Modal';
import Button from '@src/components/Button';
import LoaderSpinner from '@src/components/LoaderSpinner';

import { useAccount } from '@src/hooks/useAccount';

export default function ModalSignatureWaysPlayPending({ onClose }) {
  const { account } = useAccount();

  const [isLoading, setIsLoading] = useState(false);

  const [invoiceData, setInvoiceData] = useState();

  useEffect(() => {
    if (account) {
      setIsLoading(true);

      billingAPI
        .getSubscriptionInvoiceData()
        .then(({ data }) => {
          console.log(data);
          setInvoiceData(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          toast.error('Não foi possível carregar a fatura, atualize a página e tente novamente', {
            autoClose: 6000,
            theme: 'colored',
          });
        });
    }
  }, [account]);

  return (
    <>
      <Modal maxWidth="700px" onClose={onClose}>
        <div className="Modal-signature-ways-play-pending">

          <h2 className="Modal-signature-ways-play-pending__title">Assinatura Ways Play</h2>

          <p className="Modal-signature-ways-play-pending__description">
            Sua fatura pode estar pendente
          </p>

          <div className="Modal-signature-ways-play-pending__content">
          </div>

          <div className="Modal-signature-ways-play-pending__buttons">
            <a href={invoiceData?.url}
              target="_blank">Visualizar fatura</a>
          </div>
        </div>
      </Modal>

      {isLoading && <LoaderSpinner />}
    </>
  );
}
