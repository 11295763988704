import { memo, useCallback } from 'react';

import './DefaultProfileImage.scss';

import DefaultProfileImageSVG from '../../assets/icons/default-profile-image.svg';

function DefaultProfileImage({ src, alt, style, isChangeImg }) {
  const SelectWhichImageShows = useCallback(() => {
    if (isChangeImg) {
      return <div className="Default-profile-image__loading" />;
    }

    if (src) {
      return <img src={src} alt={alt} loading="lazy" />;
    }

    return <DefaultProfileImageSVG />;
  }, [isChangeImg, src, alt]);

  return (
    <div className="Default-profile-image" style={style}>
      {SelectWhichImageShows()}
    </div>
  );
}

export default memo(DefaultProfileImage);
