import ReactGA from 'react-ga4';

export function initGoogleAnalytics() {
  if(!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) return;
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    gtagOptions: {
      debug_mode:
        window.location.hostname.match(/localhost/) ||
        window.location.hostname.match(/\.hmg\./)
    },
  });
  ReactGA.send(window.location.pathname + window.location.search);
}

export function addGAEvent(eventName, params = {}) {
  if(!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) return;
  ReactGA.event(eventName, params);
}
