/* eslint-disable react/button-has-type */
import { memo } from 'react';
import classnames from 'classnames';
import './Button.scss';

function Button({
  children,
  styleButton = 'primary',
  type,
  className,
  ...rest
}) {
  return (
    <button
      type={type || 'button'}
      className={classnames('Button', `Button--${styleButton}`, className)}
      {...rest}
    >
      {children}
    </button>
  );
}

export default memo(Button);
