import { useCallback, useContext, useState } from 'react';
import './styles.scss';
import { TutorialStoreContext } from '@src/store/tutorialStore';
import Button from '@src/components/Button';
import LoaderSpinner from '@src/components/LoaderSpinner';

export default function TutorialOutputOverlay() {
  const { setIsShowTutorial } = useContext(TutorialStoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const exitTutorial = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => setIsShowTutorial(false), 1000);
  }, [setIsShowTutorial]);

  return (
    <>
      <div className="Tutorial-output-overlay">
        <span>Você está no tutorial. Deseja sair?</span>
        <Button styleButton="secondary" onClick={exitTutorial}>
          Sair do tutorial
        </Button>
      </div>

      {isLoading && <LoaderSpinner />}
    </>
  );
}
