import { Navigate, Outlet } from 'react-router-dom';
import { useAccount } from '@src/hooks/useAccount';

export function RequireAuthRoutes({ allowedRoles }) {
  const { account } = useAccount();

  if (!account?.id) {
    return <Navigate to="/login" replace />;
  }

  return allowedRoles.includes(account?.role) ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
}
