import * as Yup from 'yup';

import { isValidCPF } from '@src/utils/isValidCPF';

const SignatureWaysPlaySchema = Yup.object().shape({
  cpf: Yup.string()
    .required('CPF é obrigatório')
    .min(14, 'CPF inválido')
    .test('is-valid-cpf', 'CPF inválido', (cpf) => isValidCPF(cpf)),
  zip_code: Yup.string().required('CEP é obrigatório').min(9, 'CEP inválido'),
  street: Yup.string().required('Logradouro é obrigatório'),
  number: Yup.string().required('Numero é obrigatório'),
  district: Yup.string().required('Bairro é obrigatório'),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  phonenum: Yup.string().required('Celular é obrigatório'),
  phonecc: Yup.string()
    .matches(/^\+?\d+$/, 'Ex: +55')
    .required('Cód País é obrigatório'),
  // .test('is-valid-phonecc', 'Formato inválido', (cc) => cc.match(/^\+\d+$/)),
});

export default SignatureWaysPlaySchema;
