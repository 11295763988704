/* eslint-disable react/function-component-definition */
import React, { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/styles/global.scss';

import { AccountProvider } from '@store/account';
import { initGoogleAnalytics } from '@src/infra/analytics/google-analytics';
import { createLocalStorageCache } from '@src/infra/cache/local-storage-cache';

import Routes from './routes';
import * as serviceWorker from './serviceWorker';

function App() {
  const params = new URLSearchParams(window.location.search.substring(1));
  createLocalStorageCache('@Ways:origin_url').set(document.referrer);
  createLocalStorageCache('@Ways:utms').set({
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign'),
    utm_term: params.get('utm_term'),
    utm_content: params.get('utm_content'),
  });

  useEffect(() => {
    initGoogleAnalytics();
  }, []);

  return (
    <StrictMode>
      <AccountProvider>
        <Routes />
        <ToastContainer />
      </AccountProvider>
    </StrictMode>
  );
}

export default App;

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
serviceWorker.unregister();
