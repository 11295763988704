import { useCallback, useContext } from 'react';
import { AccountContext } from '@src/store/account';
import { accountDataAdapter } from '@src/adapters/account-data-adapter';

export function useAccount() {
  const { account, setAccount } = useContext(AccountContext);

  const updateAccountData = useCallback(
    (data) => {
      setAccount((oldState) => ({ ...oldState, ...accountDataAdapter(data) }));
    },
    [setAccount]
  );

  return { account, updateAccountData };
}
