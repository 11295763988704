import { authAxiosHttpRequest } from '@src/infra/http/axios/auth-axios-http-request-decorator';
import { createWaysApiUrl } from '@src/infra/http/ways-api-url';

async function getCompanyDataByName(companyName) {
  const response = await authAxiosHttpRequest({
    url: createWaysApiUrl({
      service: `empresas/${companyName}`,
    }),
    method: 'GET',
  });
  return response;
}

export const companiesAPI = {
  getCompanyDataByName,
};
