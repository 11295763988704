export function accountDataAdapter(data) {
  function genereteNames(fullname) {
    const splitFullname = fullname.split(' ');

    return {
      fullname,
      firstAndLastName:
        splitFullname.length === 1
          ? fullname
          : `${splitFullname[0]} ${splitFullname[splitFullname.length - 1]}`,
    };
  }

  const dataAdapted = {};
  Object.entries(data).forEach(([key, value]) => {
    /*
    if (key === 'company') {
      dataAdapted[key] = {
        name: value?.nome || '',
        logo: value?.logo || '',
      };
      return;
    }
    */

    if (key === 'fullname') {
      dataAdapted.names = genereteNames(value);
      return;
    }

    dataAdapted[key] = value;
  });

  return dataAdapted;
}
