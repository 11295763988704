import { axiosHttpRequest } from './axios-http-request';

export const authAxiosHttpRequest = async (data) => {
  const accountToken = JSON.parse(localStorage.getItem('@Ways:token'));
  if (accountToken) {
    Object.assign(data, {
      headers: Object.assign(data?.headers || {}, {
        Authorization: `Bearer ${accountToken}`,
      }),
    });
  }
  return axiosHttpRequest(data);
};
