import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { companiesAPI } from '@services/ways-api/companies-api';
import { accountAPI } from '@services/ways-api/account-api';
import { createLocalStorageCache } from '@src/infra/cache/local-storage-cache';
import { parseJwt } from '@src/utils/parseJWT';

import LoaderSpinner from '@src/components/LoaderSpinner';
import { accountDataAdapter } from '../adapters/account-data-adapter';

export const AccountContext = createContext({});

export function AccountProvider({ children }) {
  const [account, setAccount] = useState({});
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const fetchCompanyDataByName = useCallback(async (companyName) => {
    try {
      const { data } = await companiesAPI.getCompanyDataByName(companyName);
      return data;
    } catch (error) {
      toast.error('Erro ao buscar a logo da sua empresa', {
        autoClose: 6000,
        theme: 'colored',
      });
      return { name: companyName };
    }
  }, []);

  const fetchAccountData = useCallback(async () => {
    try {
      const { data } = await accountAPI.getAccountData();

      setAccount(accountDataAdapter({ ...data }));
    } catch (error) {
      toast.error('Erro ao buscar seus dados', {
        autoClose: 6000,
        theme: 'colored',
      });
    }
  }, [fetchCompanyDataByName]);

  const checkTokenIsValid = useCallback((token) => {
    const decodedJwt = parseJwt(token);
    if (!decodedJwt?.exp || decodedJwt.exp * 1000 < Date.now()) return false;
    return true;
  }, []);

  useEffect(() => {
    const accountToken = createLocalStorageCache('@Ways:token').value;
    if (!accountToken) {
      setIsAuthenticating(false);
      return;
    }

    if (!checkTokenIsValid(accountToken)) {
      toast.info('Seção encerrada! Faça seu login novamente.');
      setIsAuthenticating(false);
      return;
    }

    fetchAccountData().finally(() => {
      setIsAuthenticating(false);
    });
  }, [fetchAccountData, fetchCompanyDataByName, checkTokenIsValid]);

  const providerValues = useMemo(
    () => ({
      account,
      setAccount,
    }),
    [account]
  );

  return (
    <AccountContext.Provider value={providerValues}>
      {isAuthenticating ? <LoaderSpinner /> : children}
    </AccountContext.Provider>
  );
}
